import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.dreamtoclimb.org">
        www.dreamtoclimb.org
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

export const Footer = () => {
  return (
    <Box sx={{ bgcolor: "background.paper", p: 6 }} component="footer">
      {/*<Typography variant="h6" align="center" gutterBottom>*/}
      {/*  Footer*/}
      {/*</Typography>*/}
      {/*<Typography*/}
      {/*  variant="subtitle1"*/}
      {/*  align="center"*/}
      {/*  color="text.secondary"*/}
      {/*  component="p"*/}
      {/*>*/}
      {/*  Something here to give the footer a purpose!*/}
      {/*</Typography>*/}
      <Copyright />
      <Typography variant="body2" color="text.secondary" align="center">
        {"Website by "}
        <Link
          color="inherit"
          href="https://www.stellarjaysoftware.com"
          target="_blank"
        >
          www.stellarjaysoftware.com
        </Link>
      </Typography>
    </Box>
  );
};
