import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const Values = () => {
  return (
    <Box
      sx={{
        py: 4,
        px: 2,
      }}
    >
      <Typography component="h2" variant="h3" align="center" gutterBottom>
        Our Core Values
      </Typography>
      <Typography gutterBottom>
        • To guide and teach kids the beautiful outdoors and the principals of
        climbing.
      </Typography>
      <Typography gutterBottom>
        • To be a drug free environment. Our belief is to have a clear mind and
        have a healthy body and lifestyle.
      </Typography>
      <Typography gutterBottom>
        • To not discriminated any one based on color, religion, or sexual
        orientation. Dream to Climb will encourage anyone that wishes to
        participate.
      </Typography>
      <Typography gutterBottom>
        • To not be affiliated with any political party nor will it spend any of
        the donations in lobbies for any legislation change.
      </Typography>
    </Box>
  );
};
