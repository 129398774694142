import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";

type DetailCard = {
  title: string;
  image?: any;
};

const cards: DetailCard[] = [
  {
    title: "Joshua Tree National Park",
    image:
      "https://rvca738f6h5tbwmj3mxylox3-wpengine.netdna-ssl.com/wp-content/uploads/2020/05/GI_103576215_Climbing_JoshuaTree-1536x768.jpg",
    // image: require("./images/boys.jpg"),
  },
  {
    title: "San Jacinto State Park",
    image:
      "https://www.gannett-cdn.com/-mm-/8a5f278547be4805ae08ea67ffb09c83900805f4/c=0-200-3840-2360/local/-/media/2015/07/20/PalmSprings/B9318102200Z.1_20150720123534_000_GLABC2RUS.1-0.jpg?width=660&height=372&fit=crop&format=pjpg&auto=webp",
  },
  {
    title: "Lake Perris",
    image:
      "https://cdn2.apstatic.com/photos/climb/106438185_medium_1494108754.jpg",
  },
];

export const Trips = () => {
  return (
    <>
      <Typography
        component="h2"
        variant="h3"
        align="center"
        color="text.primary"
        gutterBottom
      >
        Upcoming trips
      </Typography>
      <Grid container spacing={4}>
        {cards.map((card: DetailCard) => (
          <Grid item key={card.title} xs={12} sm={6} md={4}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {card?.image && (
                <CardMedia
                  component="img"
                  sx={
                    {
                      // 16:9
                      // pt: "56.25%",
                      // height: 100,
                    }
                  }
                  image={card.image}
                  // src={card.src}
                  // image={require("./images/boys.jpg")}
                  alt="random"
                />
              )}
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="h2">
                  {card.title}
                </Typography>
                {/*<Typography>*/}
                {/*  This is a media card. You can use this section to describe*/}
                {/*  the content.*/}
                {/*</Typography>*/}
              </CardContent>
              {/*<CardActions>*/}
              {/*  <Button size="small">View</Button>*/}
              {/*  <Button size="small">Edit</Button>*/}
              {/*</CardActions>*/}
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
