import React, { useEffect } from "react";
import landNSky from "../images/land-n-sky.jpg";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { index as theme } from "../theme";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const actionButtonStyles = { textAlign: "center", mt: 1 } as const;

interface Props {
  onDonate: () => void;
  onContact: (subject?: string) => void;
}

export const Hero = ({ onDonate, onContact }: Props) => {
  useEffect(() => {
    // setup donation button
    // @ts-ignore
    if (window.PayPal) {
      const donateRendered =
        document.getElementById("donate-button")?.innerHTML !== "";
      if (!donateRendered) {
        // @ts-ignore
        window.PayPal.Donation.Button({
          env: "production",
          hosted_button_id: "YSSW23YSHQJFA",
          image: {
            src: "/donate-button.png",
            alt: "Donate with PayPal button",
            title: "PayPal - The safer, easier way to pay online!",
          },
        }).render("#donate-button");
      }
    } else {
      console.error("PayPal sdk not loaded");
    }
  }, []);

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        pt: 8,
        pb: 6,
        backgroundImage: `url(${landNSky})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container maxWidth="sm">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
          sx={{
            color: theme.palette.primary.contrastText,
            textShadow: `2px 2px 10px ${theme.palette.grey["800"]}`,
          }}
        >
          Dream to Climb
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          paragraph
          sx={{
            backgroundColor: `rgba(255, 255,255, 0.5)`,
            borderRadius: 2,
            p: 2,
          }}
        >
          Dream to climb is a non profit entity created with its sole purpose of
          introducing the sport of climbing to underprivileged kids or any other
          that wish to participate that otherwise would not have the funds or
          have access to the beautiful outdoor climbing around the State of
          California.
        </Typography>

        <Grid container>
          <Grid item xs={12} sm={6} sx={actionButtonStyles}>
            <div id="donate-button-container">
              <div id="donate-button" />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} sx={actionButtonStyles}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onContact()}
            >
              Contact Us
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
