import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

export const DonateContent = () => {
  return (
    <Box
      sx={{
        p: 1,
      }}
    >
      <Typography>
        We're still getting setup, but excited that your interested!
      </Typography>
    </Box>
  );
};
