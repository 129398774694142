import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Brightness3Icon from "@mui/icons-material/Brightness3";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";

export const TopBar = () => {
  return (
    <AppBar position="relative">
      <Toolbar>
        <Brightness3Icon sx={{ mr: 1, transform: "rotate(150deg)" }} />
        <Typography variant="h5" color="inherit" noWrap>
          Dream to Climb
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
