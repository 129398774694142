import { colors } from "@mui/material";
import { appColors } from "./colors";
import { PaletteOptions } from "@mui/material/styles/createPalette";

// const defaultTheme = createTheme();

// NOTE: for recognized properties: https://mui.com/customization/default-theme/
//  more details: https://mui.com/customization/theming/
const lightPalette: PaletteOptions = {
  mode: "light",
  background: {
    default: appColors.grey7,
    paper: appColors.white,
  },
  primary: {
    main: appColors.orange1,
    light: appColors.orange2,
    dark: appColors.orange3,
    contrastText: appColors.white,
  },
  secondary: {
    main: appColors.blue1,
    light: appColors.blue2,
    dark: appColors.blue3,
    contrastText: appColors.white,
  },
  error: {
    main: appColors.fuchsia4,
    light: appColors.fuchsia3,
    dark: appColors.fuchsia5,
    contrastText: appColors.white,
  },
  // warning: {
  //   // light: appColors.red2,
  //   // main: appColors.red1,
  //   // dark: appColors.green1,
  //   // contrastText: appColors.white,
  // },
  // info: {
  //   // light: appColors.red2,
  //   // main: appColors.red1,
  //   // dark: appColors.green1,
  //   // contrastText: appColors.white,
  // },
  success: {
    light: appColors.purple1,
    main: appColors.purple2,
  },
  text: {
    primary: appColors.text1,
    secondary: appColors.text2,
    disabled: appColors.text3,
  },
  divider: appColors.grey6,
  common: {
    black: colors.common.black,
    white: appColors.white,
  },
};

export const palettes = {
  light: lightPalette,
};
