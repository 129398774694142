/**
 * WARNING: don't use these colors directly in the app. Use the theme.palette
 * instead so that the theme switcher will work properly
 *
 * These colors should only be referenced when initializing the themes: /theme/index.js
 */
export const appColors = {
  white: "#FFFFFF",
  orange1: "#ffa900",
  orange2: "#FFD382",
  orange3: "#865700",
  blue1: "#0041D5",
  blue2: "#6C9DFF",
  blue3: "#002368",
  green1: "rgba(1, 217, 198, 0.08)",
  green2: "rgba(199, 255, 248, 1)",
  green3: "rgba(1, 217, 198, 0.5)",
  green4: "rgba(1, 217, 198, 1)",
  green5: "rgba(61, 172, 158, 1)",
  green6: "rgba(1, 217, 198, 1)",
  purple1: "rgba(242, 242, 255, 1)",
  purple2: "rgba(216, 216, 255, 1)",
  purple3: "rgba(146, 146, 255, 1)",
  purple4: "rgba(111, 121, 212, 1)",
  purple5: "rgba(78, 87, 170, 1)",
  fuchsia1: "rgba(245, 0, 87, 0.08)",
  fuchsia2: "rgba(245, 0, 87, 0.5)",
  fuchsia3: "rgba(255, 64, 129, 1)",
  fuchsia4: "rgba(245, 0, 87, 1)",
  fuchsia5: "rgba(197, 17, 98, 1)",
  silver1: "#CDCDDB",
  silver2: "#EDEDF4",
  // blue1: "#2C2CFE",
  black: "#000000",
  text1: "rgba(45, 42, 92, 0.95)",
  text2: "rgba(0, 0, 0, 0.6)",
  text3: "rgba(0, 0, 0, 0.38)",
  grey1: "#393A3D",
  grey2: "#595A5F",
  grey3: "#77797E",
  grey4: "#96979C",
  grey5: "#B5B7BA",
  grey6: "#D5D6D7",
  grey7: "#F2F2F2",
  grey8: "#F9FAFA", // not in design specs
  red1: "#FF0000", // not in design specs
  red2: "#d32f2f", // not in design specs
};
