import { createTheme } from "@mui/material/styles";
import { palettes } from "./pallettes";
import { getTypography } from "./typography";
import { getComponents } from "./components";

export const index = createTheme({
  palette: palettes.light,
  typography: getTypography(palettes.light),
  components: getComponents(palettes.light),
});
