import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "./App.css";
import Container from "@mui/material/Container";
import { index as theme } from "./theme/index";
import { logEvent } from "firebase/analytics";
import { analytics } from "./firebase";

import { AppDialog } from "./components/AppDialog";
import { useState } from "react";
import { DonateContent } from "./components/DonateContent";
import { TopBar } from "./components/TopBar";
import { Hero } from "./components/Hero";
import { Trips } from "./components/Trips";
import { Footer } from "./components/Footer";
import { Values } from "./components/Values";

export default function App() {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleContact = (subject?: string) => {
    logEvent(analytics, "contact_pressed");
    const emailSubject =
      subject || "I'd like more information about Dream to Climb";
    window.open(`mailto:dreamtoclimb@gmail.com?subject=${emailSubject}`);
  };

  const handleDonate = () => {
    logEvent(analytics, "donate_pressed");
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <TopBar />
      <main>
        <Hero onContact={handleContact} onDonate={handleDonate} />
        <Container sx={{ py: 8 }} maxWidth="md">
          <Trips />
          <Values />
        </Container>
      </main>
      <Footer />
      <AppDialog
        open={dialogOpen}
        onConfirm={() => {
          handleContact("I'd like to donate");
        }}
        hideDenyButton={true}
        onDeny={() => setDialogOpen(false)}
        content={<DonateContent />}
        confirmText="Contact Us"
      />
    </ThemeProvider>
  );
}
