import { Components } from "@mui/material";
import { PaletteOptions } from "@mui/material/styles/createPalette";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import { getGlobalStyles } from "./globalStyles.js";

export const getComponents = (palette: PaletteOptions): Components => ({
  MuiButton: {
    styleOverrides: {
      root: {
        backgroundColor: "primary.main",
        borderRadius: 20,
        textTransform: "none",
        minWidth: 180,
        boxShadow: "none",
        fontSize: 16,
      },
      contained: {
        "&:hover": {
          // @ts-ignore
          backgroundColor: palette?.primary?.light,
          boxShadow: "none",
        },
      },
      containedSecondary: {
        "&:hover": {
          // @ts-ignore
          backgroundColor: palette?.secondary?.light,
          boxShadow: "none",
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        "&.outlined": {
          // @ts-ignore
          backgroundColor: palette?.primary?.contrastText,
          border: "solid 1px",
          // @ts-ignore
          borderColor: palette?.primary?.main,
          "& .MuiSvgIcon-root": {
            // @ts-ignore
            color: palette?.primary?.main,
          },
          "&:hover": {
            backgroundColor: "#e0e0e0",
          },
        },
        "&.inverse": {
          // @ts-ignore
          backgroundColor: palette?.primary?.main,
          "& .MuiSvgIcon-root": {
            // @ts-ignore
            color: palette?.primary?.contrastText,
          },
          "&:hover": {
            // @ts-ignore
            backgroundColor: palette?.primary?.light,
          },
        },
        "&.inverseSecondary": {
          // @ts-ignore
          backgroundColor: palette?.secondary?.main,
          "& .MuiSvgIcon-root": {
            // @ts-ignore
            color: palette?.secondary?.contrastText,
          },
          "&:hover": {
            // @ts-ignore
            backgroundColor: palette?.secondary?.light,
          },
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        "&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
          // @ts-ignore
          borderColor: palette?.secondary?.main,
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        "&.Mui-focused": {
          // @ts-ignore
          color: palette?.secondary?.main,
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        "&.Mui-checked": {
          // @ts-ignore
          color: palette?.secondary?.main,
        },
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        "&.Mui-checked": {
          // @ts-ignore
          color: palette?.secondary?.main,
        },
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        "& .MuiSwitch-switchBase.Mui-checked": {
          // @ts-ignore
          color: palette?.secondary?.main,
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
          // @ts-ignore
          backgroundColor: palette?.secondary?.main,
        },
      },
    },
  },
});
