import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

interface Props {
  open: boolean;
  content?: string | JSX.Element;
  title?: string;
  onConfirm: () => void;
  confirmText?: string;
  onDeny?: () => void;
  hideDenyButton?: boolean;
  denyText?: string;
}

export const AppDialog = ({
  open,
  content,
  title,
  onConfirm,
  confirmText = "Ok",
  onDeny,
  denyText = "No",
  hideDenyButton,
}: Props): JSX.Element => {
  return (
    <Dialog
      open={open}
      onClose={onDeny ? onDeny : onConfirm}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
        }}
        aria-label="delete"
        onClick={onDeny ? onDeny : onConfirm}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle
        id="alert-dialog-title"
        // disableTypography={true}
      >
        {/*<Typography variant="h3">{title}</Typography>*/}
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          component="div"
          sx={{ p: 2 }}
        >
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          pb: 2,
        }}
      >
        {onDeny && !hideDenyButton && (
          <Button onClick={() => onDeny()} color="primary" variant="contained">
            {denyText}
          </Button>
        )}
        <Button
          onClick={() => onConfirm()}
          color="secondary"
          variant="contained"
          autoFocus
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
