import { PaletteOptions } from "@mui/material/styles/createPalette";

export const getTypography = (palette: PaletteOptions) => ({
  fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
  // fontWeightLight: 400,
  // fontWeightRegular: 500,
  // fontWeightMedium: 700,
  // fontWeightBold: 800,
  h1: {
    fontSize: 40,
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "52px",
    letterSpacing: "-0.16px",
    color: palette?.text?.primary,
  },
  h2: {
    fontSize: 34,
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "44px",
    letterSpacing: "-0.16px",
    color: palette?.text?.primary,
  },
  h3: {
    fontSize: 28,
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "36px",
    letterSpacing: "-0.16px",
    color: palette?.text?.primary,
  },
  h4: {
    fontSize: 24,
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "32px",
    letterSpacing: "-0.16px",
    color: palette?.text?.primary,
  },
  h5: {
    fontSize: 20,
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "28px",
    letterSpacing: "-0.16px",
    color: palette?.text?.primary,
  },
  h6: {
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "24px",
    letterSpacing: "-0.16px",
    color: palette?.text?.primary,
  },
  body1: {
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "-0.2px",
    color: palette?.text?.primary,
  },
  body2: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    color: palette?.text?.primary,
  },
  overline: {
    fontWeight: 500,
  },
});
