// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyB7tZqHaN2TDOfBdgT41EfC7AC8otfroM8",
  authDomain: "dreamtoclimb.firebaseapp.com",
  projectId: "dreamtoclimb",
  storageBucket: "dreamtoclimb.appspot.com",
  messagingSenderId: "647452696532",
  appId: "1:647452696532:web:ed76f19dc5fdaefbaa0041",
  measurementId: "G-K299CK0KLP",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
